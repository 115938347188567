<template>
  <div class="admin dashboard">

    <header class="admin-header cf">
      <h1>Quittungen</h1>
      <div class="meta">
        <a @click="modal_add = true" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
          </svg>
          <span>Quittung hinzufügen</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="row row-gutter-20">
        <div class="col-12">
          <div class="search-wrap" style="margin-bottom:10px">
            <input v-model="search_phrase" v-on:input="search_handler" type="text" class="form-search" style="width:81%">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>
        <div class="col-12">
          <div style="text-align:right;padding:8px 0 0 0">
            <ul class="nav nav-tabs">
              <li :class="check_filter_class('all')"><a @click="select_filter_class('all')" style="margin-left:10px">Alle</a></li>
              <li :class="check_filter_class('unpaid')"><a @click="select_filter_class('unpaid')" style="margin-left:10px">Nur unbezahlt</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="filterbar cf">
        <ul class="nav nav-tabs">
          <li :class="check_date_class('today')"><a @click="select_date_class('today')">Heute</a></li>
          <li :class="check_date_class('yesterday')"><a @click="select_date_class('yesterday')">Gestern</a></li>
          <li :class="check_date_class('all')"><a @click="select_date_class('all')">Gesamt</a></li>
          <li :class="check_date_class('cal')">
            <a @click="select_date_class('cal')">
              <svg style="display:block;width:18px" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            </a>
            <div v-if="show_cal" class="cal-overlay">
              <v-date-picker v-model="app_range" :first-day-of-week="2" locale="de" color="red" is-range>
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row row-gutter-10">
                    <div class="col-12">
                      <div class="form-wrap">
                        <label>Zeitraum von</label>
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="form-input"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-wrap">
                        <label>Zeitraum bis</label>
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="form-input"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </v-date-picker>

            </div>
          </li>
        </ul>
        <ul class="nav nav-tabs">
          <li v-if="computed_locations.length > 1" key="" :class="check_location_class('')">
            <a @click="select_location_class('')">Alle Standorte</a>
          </li>
          <li v-for="location in computed_locations" v-bind:key="location.id" :class="check_location_class(location.id)">
            <a @click="select_location_class(location.id)">{{ location.name }}</a>
          </li>
        </ul>
      </div>

      <div v-if="search_loading" class="loading-wrap">
        <span class="loading-spinner"></span>
      </div>
      <div v-else>

        <div v-if="receipts.length == 0" class="alert alert-info">
          <p>Aktuell sind noch keine Quittungen vorhanden.</p>
        </div>
        <div v-else>
          <table class="table">
            <thead>
              <tr>
                <th>Nummer</th>
                <th>Empfänger</th>
                <th>Standort</th>
                <th>Erstellt am</th>
                <th>Bezahlung</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="receipt in receipts" v-bind:key="receipt.id">
                <td width="15%">
                  {{ receipt.receipt_number }}
                </td>
                <td width="20%">
                  {{ receipt.recipient }}
                </td>
                <td width="15%">
                  <span v-if="receipt && receipt.location">{{ receipt.location.name }}</span>
                </td>
                <td width="20%">
                  {{ receipt.created_at_formatted }}
                </td>
                <td width="20%">
                  <div style="text-align:center;border:2px solid #f1f1f1;padding: 5px;font-size: 14px;border-radius: 3px">
                    <span v-if="receipt.payment_status == 'paid'" class="price" style="color:#07B400;">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" style="width:14px;height:14px;display:inline-block;vertical-align:middle;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span style="display:inline-block;vertical-align:middle;font-weight:600;margin-left:5px;">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'eur', }).format(receipt.payment_amount) }}</span>
                    </span>
                    <span v-else class="price" style="color:#CC0000;">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" style="width:14px;height:14px;display:inline-block;vertical-align:middle;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span style="display:inline-block;vertical-align:middle;font-weight:600;margin-left:5px;">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'eur', }).format(receipt.payment_amount) }}</span>
                    </span>
                  </div>
                </td>
                <td width="10%" class="actions">
                  <router-link :to="'/admin/receipts/'+receipt.id">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </router-link>
                  <a v-if="user.role == 'administrator' && receipt.payment_status == 'pending'" @click="delete_receipt(receipt)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add">
      <svg @click="modal_add = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Quittung hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="category_id">Standort</label>
          <select v-model="receipt.location_id" class="form-input">
            <option v-for="location in computed_locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <div class="form-wrap">
          <label for="recipient" class="form-label">Empfänger</label>
          <textarea v-model="receipt.recipient" rows="6" class="form-input input-grey" id="recipient" name="recipient"></textarea>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_receipt" class="button button-red button-100">Quittung erstellen ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
export default {
  name: 'admin_receipts',
  data () {
    return {
      loading: true,
      locations: [],
      receipts: [],
      receipt: {},
      user: {},
      user_location: null,
      modal_add: false,
      search_loading: false,
      search_phrase: "",
      searching: false,
      class_date: "today",
      class_location: "",
      class_filter: "all",
      app_range: {
        start: null,
        end: null
      },
      show_cal: false,
    }
  },
  computed: {
    computed_locations: function () {
      var locations = [];
      this.locations.forEach(location => {
        if (this.user_location) {
          if (this.user_location == location.id) {
            locations.push(location);
          }
        } else {
          locations.push(location);
        }
      })
      return locations;
    },
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.locations = response.data.locations;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user_id = response.data.user.id;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/'+this.user_id, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user = response.data.user;
        if (this.user.locations && this.user.locations.length > 0) {
          this.user_location = this.user.locations[0].id;
          this.receipt.location_id = this.user_location;
        }
      })
      this.get_receipts();
    },
    async get_receipts() {
      this.search_loading = true;
      if (this.user_location) {
        this.class_location = this.user_location;
      }
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/receipts', {
        params: {
          filter_date: this.class_date,
          location_id: this.class_location,
          filter_val: this.class_filter,
          range_from: this.app_range.start,
          range_to: this.app_range.end
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.receipts = response.data.receipts;
      })
      this.loading = false;
      this.search_loading = false;
    },
    create_receipt() {
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/receipts', this.receipt, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.get_data();
        this.modal_add = false;
        this.receipt = {};
        if (this.user_location) {
          this.receipt.location_id = null;
          this.receipt.location_id = this.user_location;
        }
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
        this.$router.push('/admin/receipts/'+response.data.receipt.id);
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    delete_receipt(receipt) {
      if(confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/receipts/'+receipt.id, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.$notify({
            title: "Erfolgreich gelöscht.",
            type: "success"
          });
          this.get_data();
        })
      }
    },
    search_handler() {
      if (this.searching == false) {
        this.search_loading = true;
        this.searching = true;
        this.$http.get(process.env.VUE_APP_BASE_API+'/admin/receipts', {
          params: {
            search_phrase: this.search_phrase,
            filter_date: this.class_date,
            location_id: this.class_location,
            filter_val: this.class_filter,
            range_from: this.app_range.start,
            range_to: this.app_range.end
          },
          headers: { Authorization: this.$store.getters.get_token }
        })
        .then(response => {
          this.receipts = response.data.receipts;
          this.search_loading = false;
          this.searching = false;
        })
      }
    },
    check_date_class(date) {
      if (date == this.class_date) {
        if (date == "cal") {
          return 'active cal-sel';
        } else {
          return 'active';
        }
      } else {
        if (date == "cal") {
          return 'cal-sel';
        } else {
          return '';
        }
      }
    },
    select_date_class(date) {
      this.show_cal = !this.show_cal;
      this.class_date = date;
      if (date != "cal") {
        this.show_cal = false;
        this.get_receipts();
      }
    },
    check_filter_class(date) {
      if (date == this.class_filter) {
        return 'active';
      } else {
        return '';
      }
    },
    select_filter_class(filter) {
      this.class_filter = filter;
      this.get_receipts();
    },
    check_location_class(location) {
      if (location == this.class_location) {
        return 'active';
      } else {
        return '';
      }
    },
    select_location_class(location) {
      this.class_location = location;
      this.get_receipts();
    },
  },
  mounted () {
    this.get_data();
  },
  watch: {
    app_range() {
      this.search_loading = true;
      this.show_cal = false;
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/receipts', {
        params: {
          range_from: this.app_range.start,
          range_to: this.app_range.end,
          location_id: this.class_location
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.receipts = response.data.receipts;
        this.search_loading = false;
      })
    },
  }
}
</script>
